import imgAuthor1 from "../images/avatar/avt-1.jpg"
import img1left from "../images/box-item/collection-item-2.jpg"
import img1right1 from "../images/box-item/collection-item-top-1.jpg"
import img1right2 from "../images/box-item/collection-item-top-2.jpg"
import img1right3 from "../images/box-item/collection-item-bottom-4.jpg"
import imgAuthor2 from "../images/avatar/avt-8.jpg"
import img2left from "../images/box-item/img-collection23.jpg"
import img2right1 from "../images/box-item/img-collection24.jpg"
import img2right2 from "../images/box-item/img-collection10.jpg"
import img2right3 from "../images/box-item/img-collection11.jpg"
import imgAuthor3 from "../images/avatar/avt-7.jpg"
import img3left from "../images/box-item/img-collection12.jpg"
import img3right1 from "../images/box-item/img-collection18.jpg"
import img3right2 from "../images/box-item/img-collection25.jpg"
import img3right3 from "../images/box-item/img-collection17.jpg"
import img1right4 from "../images/box-item/img-collection8.jpg"
import img2right4 from "../images/box-item/img-collection1.jpg"
import imgtop1 from "../images/box-item/collection-item-4.jpg"
import imgtop2 from "../images/box-item/collection-item-5.jpg"
import imgtop3 from "../images/box-item/collection-item-1.png"
import img4left from "../images/box-item/image-box-15.jpg"
import img4right1 from "../images/box-item/image-box-18.jpg"
import img4right2 from "../images/box-item/image-box-14.jpg"
import img4right3 from "../images/box-item/img-collection13.jpg"
import img5left from "../images/box-item/img-collection14.jpg"
import img5right1 from "../images/box-item/img-collection15.jpg"
import img5right2 from "../images/box-item/img-collection16.jpg"
import img5right3 from "../images/box-item/img-collection19.jpg"
import img6left from "../images/box-item/image-box-17.jpg"
import img6right1 from "../images/box-item/img-collection22.jpg"
import img6right2 from "../images/box-item/img-collection21.jpg"
import img6right3 from "../images/box-item/img-collection20.jpg"

const popularCollectionData = [
  {
    title: "Creative Art Collection",
    imgAuthor: imgAuthor1,
    name: "Ralph Garraway",
    imgleft: img1left,
    imgright1: img1right1,
    imgright2: img1right2,
    imgright3: img1right3,
    imgright4: img1right4,
    imgtop: imgtop1,
    wishlist: "100",
    count: "12 item products"
  },
  {
    title: "Colorful Abstract",
    imgAuthor: imgAuthor2,
    name: "Mason Woodward",
    imgleft: img2left,
    imgright1: img2right1,
    imgright2: img2right2,
    imgright3: img2right3,
    imgright4: img1right4,
    imgtop: imgtop2,
    wishlist: "220",
    count: "26 item products"
  },
  {
    title: "Modern Art Collection",
    imgAuthor: imgAuthor3,
    name: "Freddie Carpenter",
    imgleft: img3left,
    imgright1: img3right1,
    imgright2: img3right2,
    imgright3: img3right3,
    imgright4: img2right4,
    imgtop: imgtop3,
    wishlist: "90",
    count: "26 item products"
  },
  {
    title: "Creative Art Collection",
    imgAuthor: imgAuthor1,
    name: "Ralph Garraway",
    imgleft: img4left,
    imgright1: img4right1,
    imgright2: img4right2,
    imgright3: img4right3,
    imgright4: img1right4,
    imgtop: imgtop1,
    wishlist: "145",
    count: "12 item products"
  },
  {
    title: "Colorful Abstract",
    imgAuthor: imgAuthor2,
    name: "Mason Woodward",
    imgleft: img5left,
    imgright1: img5right1,
    imgright2: img5right2,
    imgright3: img5right3,
    imgright4: img1right4,
    imgtop: imgtop2,
    wishlist: "100",
    count: "26 item products"
  },
  {
    title: "Modern Art Collection",
    imgAuthor: imgAuthor3,
    name: "Freddie Carpenter",
    imgleft: img6left,
    imgright1: img6right1,
    imgright2: img6right2,
    imgright3: img6right3,
    imgright4: img2right4,
    imgtop: imgtop3,
    wishlist: "220",
    count: "26 item products"
  },
  {
    title: "Creative Art Collection",
    imgAuthor: imgAuthor1,
    name: "Ralph Garraway",
    imgleft: img1left,
    imgright1: img1right1,
    imgright2: img1right2,
    imgright3: img1right3,
    imgright4: img1right4,
    imgtop: imgtop1,
    wishlist: "100",
    count: "12 item products"
  },
  {
    title: "Colorful Abstract",
    imgAuthor: imgAuthor2,
    name: "Mason Woodward",
    imgleft: img2left,
    imgright1: img2right1,
    imgright2: img2right2,
    imgright3: img2right3,
    imgright4: img1right4,
    imgtop: imgtop2,
    wishlist: "220",
    count: "26 item products"
  },
  {
    title: "Modern Art Collection",
    imgAuthor: imgAuthor3,
    name: "Freddie Carpenter",
    imgleft: img3left,
    imgright1: img3right1,
    imgright2: img3right2,
    imgright3: img3right3,
    imgright4: img2right4,
    imgtop: imgtop3,
    wishlist: "90",
    count: "26 item products"
  },
  {
    title: "Creative Art Collection",
    imgAuthor: imgAuthor1,
    name: "Ralph Garraway",
    imgleft: img4left,
    imgright1: img4right1,
    imgright2: img4right2,
    imgright3: img4right3,
    imgright4: img1right4,
    imgtop: imgtop1,
    wishlist: "145",
    count: "12 item products"
  },
  {
    title: "Colorful Abstract",
    imgAuthor: imgAuthor2,
    name: "Mason Woodward",
    imgleft: img5left,
    imgright1: img5right1,
    imgright2: img5right2,
    imgright3: img5right3,
    imgright4: img1right4,
    imgtop: imgtop2,
    wishlist: "100",
    count: "26 item products"
  },
  {
    title: "Modern Art Collection",
    imgAuthor: imgAuthor3,
    name: "Freddie Carpenter",
    imgleft: img6left,
    imgright1: img6right1,
    imgright2: img6right2,
    imgright3: img6right3,
    imgright4: img2right4,
    imgtop: imgtop3,
    wishlist: "220",
    count: "26 item products"
  }
]

export default popularCollectionData
