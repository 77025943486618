import img1 from "../images/avatar/avt-1.jpg"
import img2 from "../images/avatar/avt-2.jpg"
import img3 from "../images/avatar/avt-3.jpg"
import img4 from "../images/avatar/avt-4.jpg"
import img5 from "../images/avatar/avt-5.jpg"
import img6 from "../images/avatar/avt-6.jpg"
import img7 from "../images/avatar/avt-7.jpg"
import img8 from "../images/avatar/avt-8.jpg"
import img9 from "../images/avatar/avt-9.jpg"

const topSellerData = [
  {
    img: img1,
    name: "Crispin Berry",
    price: "214.2 ETH",
    classPadding: ""
  },
  {
    img: img2,
    name: "Samson Frost",
    price: "205.43 ETH",
    classPadding: "pl-17"
  },
  {
    img: img4,
    name: "Tommy Alrez",
    price: "170.3 ETH",
    classPadding: "pl-34"
  },
  {
    img: img5,
    name: "Windsor Lane",
    price: "120.7 ETH",
    classPadding: "pl-51"
  },
  {
    img: img3,
    name: "Andy Hurlbutt",
    price: "82.79 ETH",
    classPadding: "pl-68"
  },
  {
    img: img8,
    name: "Blake Banks",
    price: "68.2 ETH",
    classPadding: ""
  },
  {
    img: img6,
    name: "Monica Lucas",
    price: "52.8 ETH",
    classPadding: "pl-17"
  },
  {
    img: img9,
    name: "Matt Ramos",
    price: "38.4 ETH",
    classPadding: "pl-34"
  },
  {
    img: img7,
    name: "Harper Wilcher",
    price: "29.2 ETH",
    classPadding: "pl-51"
  },
  {
    img: img1,
    name: "Crispin Berry",
    price: "214.2 ETH",
    classPadding: "pl-68"
  },
  {
    img: img1,
    name: "Crispin Berry",
    price: "214.2 ETH",
    classPadding: ""
  },
  {
    img: img2,
    name: "Samson Frost",
    price: "205.43 ETH",
    classPadding: "pl-17"
  }
]

export default topSellerData
