import React from "react"
import { Link } from "react-router-dom"
import icon1 from "../../../assets/images/icon/Wallet.png"
import icon2 from "../../../assets/images/icon/Category.png"
import icon3 from "../../../assets/images/icon/Image2.png"
import icon4 from "../../../assets/images/icon/Bookmark.png"

const Create = () => {
  const data = [
    {
      title: "AI 서비스 개발",
      description:
        "Once you’ve set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.",
      icon: icon1,
      colorbg: "icon-color1"
    },
    {
      title: "시스템 설계 / 개발",
      description:
        "Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.",
      icon: icon2,
      colorbg: "icon-color2"
    },
    {
      title: "DevOps / 클라우드",
      description:
        "Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats",
      icon: icon3,
      colorbg: "icon-color3"
    },
    {
      title: "IT 교육",
      description:
        "Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!",
      icon: icon4,
      colorbg: "icon-color4"
    }
  ]
  return (
    <section className="tf-box-icon create tf-section bg-home-3">
      <div className="themesflat-container">
        <div className="row">
          {data.map((item, index) => (
            <CreateItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

const CreateItem = (props) => (
  <div className="col-lg-3 col-md-6 col-12">
    <div className="sc-box-icon">
      <div className="image center">
        <div className={`icon-create ${props.item.colorbg}`}>
          <img src={props.item.icon} alt="" />
        </div>
      </div>
      <h3 className="heading">
        <Link to="/wallet-connect">{props.item.title}</Link>
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
)

export default Create
