import img1 from "../images/box-item/card-item-3.jpg"
import imga1 from "../images/avatar/avt-1.jpg"
import img2 from "../images/box-item/card-item-4.jpg"
import imga2 from "../images/avatar/avt-2.jpg"
import img3 from "../images/box-item/card-item-2.jpg"
import imga3 from "../images/avatar/avt-4.jpg"
import img4 from "../images/box-item/card-item-7.jpg"
import imga4 from "../images/avatar/avt-3.jpg"
import img5 from "../images/box-item/card-item8.jpg"
import imga5 from "../images/avatar/avt-12.jpg"
import img6 from "../images/box-item/card-item-9.jpg"
import imga6 from "../images/avatar/avt-1.jpg"
import img7 from "../images/box-item/image-box-6.jpg"
import imga7 from "../images/avatar/avt-4.jpg"
import img8 from "../images/box-item/image-box-11.jpg"
import imga8 from "../images/avatar/avt-3.jpg"
import imgCollection1 from "../images/avatar/avt-18.jpg"

const todayPickData = [
  {
    img: img1,
    title: "1he RenaiXance Rising the sun ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img2,
    title: "Space babe - Night 2/25 ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    feature: "coming soon",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img3,
    title: "CyberPrimal 042 LAN",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img4,
    title: "Crypto Egg Stamp #5 ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img5,
    title: "Travel Monkey Club #45 ",
    tags: "bsc",
    imgAuthor: imga5,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img6,
    title: "Sir. Lion Swag #371 ",
    tags: "bsc",
    imgAuthor: imga6,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img7,
    title: "Cyber Doberman #766",
    tags: "bsc",
    imgAuthor: imga7,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img8,
    title: "Living Vase 01 by Lanz...",
    tags: "bsc",
    imgAuthor: imga8,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img1,
    title: "The RenaiXance Rising the sun ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img2,
    title: "Space babe - Night 2/25 ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    feature: "coming soon",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img3,
    title: "CyberPrimal 042 LAN",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img4,
    title: "Crypto Egg Stamp #5 ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img5,
    title: "Travel Monkey Club #45 ",
    tags: "bsc",
    imgAuthor: imga5,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img6,
    title: "Sir. Lion Swag #371 ",
    tags: "bsc",
    imgAuthor: imga6,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img7,
    title: "Cyber Doberman #766",
    tags: "bsc",
    imgAuthor: imga7,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  },
  {
    img: img8,
    title: "Living Vase 01 by Lanz...",
    tags: "bsc",
    imgAuthor: imga8,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D"
  }
]

export default todayPickData
