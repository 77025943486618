import img1 from "../images/slider/slide_2.png"
import img2 from "../images/slider/slide_2.png"
import imgbg1 from "../images/slider/bg_slide_2.png"
import imgbg2 from "../images/slider/bg_slide_2.png"

const heroSliderData = [
  /*{
    title_1: "Teamsoft !!!",
    title_2: "extraordinary",
    title_3: "Monster ",
    title_4: "NFTs",
    description: "Teamsoft for monster character cllections non fungible token NFTs",
    img: img1,
    imgbg: imgbg1,
    class: "left"
  },*/
  {
    title_1: "Better Service",
    title_2: "고객을 위한 IT 서비스",
    title_3: "TeamSoft ",
    title_4: "NFTs",
    description: "IT 서비스에 가치를 창조합니다.",
    img: img2,
    imgbg: imgbg2,
    class: "center"
  }
  /*,
  {
    title_1: "Discover, find,",
    title_2: "Sell extraordinary",
    title_3: "Monster ",
    title_4: "NFTs",
    description: "Teamsoft for monster character cllections non fungible token NFTs",
    img: img2,
    imgbg: imgbg2,
    class: "right"
  }*/
]

export default heroSliderData
